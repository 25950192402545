! function(n, e, s, i) {
    n.sfNavMulti = function(t, l) {
        var a = {
                mobileWidth: 991,
                overlay: !0,
                overlayColor: "rgba(0, 0, 0, 0.7)",
                hideScrollBar: !0,
                offCanvasSide: "left",
                onInit: function() {},
                onShowOffCanvas: function() {},
                onHideOffCanvas: function() {},
                transformInit: !1,
                transformThreshold: 1,
                transformCutoff: 0,
                iconMenu: '<i class="fa fa-ellipsis-h fa-lg" aria-hidden="true"></i>',
                fixedInit: !1,
                fixedId: "#sf-nav-id",
                topSpace: 0,
                leftFlag: !1,
                transformFlag: !0,
                transformPaddingOne: "p-3",
                transformPaddingLower: "p-2",
                transformTheme: "dark-theme",
                transformEffectHover: "underline",
                fullScreenFlag: !0,
                fullScreenTheme: "dark-theme",
                fixedFlag: !0,
                fixedClass: "nav-fixed",
                mobileFlag: !0,
                transferHeadingItem: !1,
                searchContainer: !1,
                horizontalSubmenu: !1
            },
            o = this,
            r = 0,
            f = 0;
        o.settings = {};
        n(t), t = t;
        o.init = function() {
            if (o.settings = n.extend({}, a, l), o.settings.leftFlag) m();
            else {
                var i = n(t).find(".center-section");
                i.find("> ul.nav-items").hasClass("flex-wrap") || i.find("> ul.nav-items").addClass("flex-wrap"), i.hasClass("overflow-hidden") && i.removeClass("overflow-hidden"), i.attr("style", ""), n(t).find(".btn-mobile").on("click touchstart", function(n) {
                    n.stopPropagation(), n.preventDefault(), o.showOffcanvas(), v(), s.body.style.overflow = "hidden"
                }), n(t).find(".sf-close").on("click touchstart", function() {
                    o.hideOffcanvas(), s.body.style.overflow = "auto"
                }), o.settings.fullScreenFlag && n(t).find(".btn-full-screen").on("click touchstart", function() {
                    u(), o.showNav()
                }), o.settings.transformFlag && (r = p(), f = b(), n(t).find(".btn-search, .sf-close").on("click touchstart keydown", function(e) {
                    e.stopPropagation(), e.preventDefault();
                    var s = e.keyCode || e.which;
                    "click" === e.type || "touchstart" === e.type || "keydown" === e.type && 13 == s ? o.toggleSearch() : 9 == s && n(e.target).blur()
                }), n(e).resize(function() {
                    p() < r || b() < f ? (d(!0), d(!1)) : (p() > r || b() > f) && (d(!0), d(!1)), r = p(), f = b(), o.settings.horizontalSubmenu
                }), d(!1)), o.settings.fixedFlag && h(), o.settings.overlay && n(t).append('<div class="nav-overlay"></div>'), o.settings.transferHeadingItem && c(), o.settings.horizontalSubmenu
            }
        };
        var c = function() {
            n(t).find(".transfer-item").css("white-space", "normal")
        };
        o.showOffcanvas = function() {
            if (o.settings.hideScrollBar && n("body").addClass("no-scroll"), o.settings.overlay && n(t).find(".nav-overlay").css("background-color", o.settings.overlayColor).fadeIn(300).on("click touchstart", function() {
                    o.hideOffcanvas(), s.body.style.overflow = "auto"
                }), "left" == o.settings.offCanvasSide) {
                n(t).find(".center-section").css("transition-property", "left").addClass("open-mobile");
                p();
                var e = n("ul.transform-submenu").html(),
                    i = n("ul.transform-submenu > li"),
                    l = n("ul.nav-menu").slice(0, i.length - 1).html();
                e ? (n("ul.transform-submenu").html(""), n("ul.transform-submenu").html(l + e), n(".transform-submenu").detach()) : e = n("ul.transform-submenu").html()
            } else n(t).find(".center-section").css("transition-property", "right").addClass("open-mobile")
        }, o.hideOffcanvas = function() {
            n(t).find(".center-section").removeClass("open-mobile").on("webkitTransitionEnd moztransitionend transitionend oTransitionEnd", function() {
                n(t).find(".center-section").css("transition-property", "none").off()
            }), n(t).find(".mobile-level").removeClass("open-mobile").on("webkitTransitionEnd moztransitionend transitionend oTransitionEnd", function() {
                n(t).find(".mobile-level").css("transition-property", "none").off()
            }), o.settings.hideScrollBar && n("body").removeClass("no-scroll"), o.settings.overlay && n(t).find(".nav-overlay").fadeOut(400)
        }, o.toggleOffcanvas = function() {
            p() <= o.settings.mobileBreakpoint && (n(t).find(".center-section").hasClass("open-mobile") ? o.hideOffcanvas() : o.showOffcanvas())
        }, o.toggleSearch = function() {
            "none" == n(t).find(".nav-search").find("form").css("display") ? (n(t).find(".nav-search").find("form").css("display", "flex"), n(t).find(".nav-search").find("input").focus(), o.settings.searchContainer && n(t).css("position", "relative")) : (n(t).find(".nav-search").find("form").css("display", "none"), n(t).find(".nav-search").find("input").blur(), o.settings.searchContainer && n(t).css("position", "static"))
        };
        var u = function() {
                o.showNav = function() {
                    n(".sf-nav-full-screen").addClass(o.settings.animation).fadeIn(300, function() {
                        n("html,body").css("overflow", "hidden")
                    }), i()
                };
                var s = function(e) {
                    var i = "";
                    return $subElem = n(e).children("div.mobile-level").children("ul.submenu-items"), $subElem.length > 0 ? ($subElem.children("li").each(function() {
                        $subLevelUp = n(this), n(this).find("ul.submenu-items").length, i += "<li>" + n(this).html().substr(0, n(this).html().indexOf("</span>", 0) + 7) + "</span>", $subLevelUp.children("div.mobile-level").children("ul.submenu-items").length > 0 ? i += '<span class="submenu-indicator item-icon"><i class="fa fa-angle-down" aria-hidden="true"></i></span></a><ul class="sf-full-screen-sub-level">' + s($subLevelUp) + "</ul></li>" : i += "</a></li>"
                    }), i += "</li>") : i
                };
                o.initFullScreen = function() {
                    var e = '<div class="sf-nav-full-screen ' + o.settings.fullScreenTheme + '"><div class="sf-nav-full-screen-header">';
                    $strE = n(t).find(".left-section").find(".nav-brand"), $strE.length > 0 && (e += '<div class="sf-nav-full-screen-brand">' + $strE[0].outerHTML + "</div>"), e += '<button class="sf-close t-1"></button>', e += '</div><div class="sf-nav-full-screen-content"><div class="sf-nav-full-screen-lists sf-scroll">', n(t).children("section.center-section").children("ul.nav-items").find("> li").each(function() {
                        if (null != this.querySelector("a.btn-transform")) n(this).children("ul.transform-submenu").find("> li").each(function() {
                            var i = '<ul class="sf-nav-full-screen-list"><li class="sf-nav-full-screen-list-header">' + n(this).html().substr(0, n(this).html().indexOf("</span>", 0) + 7) + "</span></a></li>";
                            $flagSubL = n(this), i += s($flagSubL), e += i += "</ul>"
                        });
                        else {
                            var i = '<ul class="sf-nav-full-screen-list"><li class="sf-nav-full-screen-list-header">' + n(this).html().substr(0, n(this).html().indexOf("</span>", 0) + 7) + "</span></a></li>";
                            $flagSubL = n(this), i += s($flagSubL), e += i += "</ul>"
                        }
                    }), e += "</div></div></div>", n("body").append(e)
                }, o.initEvents = function() {
                    this.settings;
                    $elem = n(".sf-nav-full-screen-lists"), $elem.children("ul").find("span.item-icon").bind("click touchstart", function(e) {
                        if (e.stopPropagation(), e.preventDefault(), n(this).parent().siblings(".sf-full-screen-sub-level").length > 0) {
                            if ("none" == n(this).parent().siblings(".sf-full-screen-sub-level").css("display")) return n(this).parent().siblings(".sf-full-screen-sub-level").css("display", "flex"), n(this).addClass("submenu-indicator-minus"), !1;
                            n(this).parent().siblings(".sf-full-screen-sub-level").css("display", "none"), n(this).hasClass("submenu-indicator-minus") && n(this).removeClass("submenu-indicator-minus")
                        }
                    })
                }, o.hideNav = function() {
                    n(".sf-nav-full-screen").removeClass("zoom").fadeOut("300"), n("html,body").css("overflow", "auto")
                };
                var i = function() {
                    var e = n(t).find(".sf-nav-full-screen-content").innerHeight(),
                        s = n(t).find(".full-screen-nav-menu, .sf-nav-full-screen-lists, .full-screen-nav-boxes, .full-screen-nav-circles, .full-screen-nav-general").innerHeight();
                    s > e ? n(t).find(".sf-nav-full-screen-content").css("padding-top", contentDefaultPaddingTop) : n(t).find(".sf-nav-full-screen-content").css("padding-top", (e - s) / 2)
                };
                o.initFullScreen(), o.initEvents(), n(".sf-nav-full-screen").children(".sf-nav-full-screen-header").children(".sf-close").on("click touchstart", function() {
                    o.hideNav()
                }), n(e).resize(function() {
                    i()
                })
            },
            d = function(e) {
                function s(n) {
                    return n !== i && Math.ceil(n.offset().top) >= g + C
                }
                var l, a, r, f, c, u, d = n(t).children(".center-section").children("ul"),
                    h = d.find("> li"),
                    m = h.first(),
                    v = h.last(),
                    b = d.find("li").length,
                    g = Math.floor(m.offset().top),
                    C = Math.floor(m.outerHeight(!0)),
                    y = !1;
                if (s(v) && b > o.settings.transformThreshold && !e && d.is(":visible")) {
                    if (p() > o.settings.mobileWidth) {
                        var S = n('<ul class="transform-submenu nav-dropdown nav-submenu m-0 p-0 flex-column mobile-level ' + o.settings.transformTheme + '"></ul>');
                        for (S.addClass(""), u = b; u > 1; u--) {
                            if (l = d.find("> li:last-child"), a = s(l), u - 1 <= o.settings.transformCutoff) {
                                n(this.children().get().reverse()).appendTo(S), y = !0;
                                break
                            }
                            if (!a) break;
                            l.appendTo(S)
                        }
                        y ? d.append('<li class="menu-item nav-item transform-allInPopup"><a href="#" class="btn-transform ' + o.settings.transformEffectHover + " " + o.settings.transformPaddingOne + '">' + o.settings.iconMenu + "</a></li>") : d.append('<li class="menu-item nav-item"><a href="#" class="btn-transform item-link ' + o.settings.transformEffectHover + " " + o.settings.transformPaddingOne + '"><span class="item-icon">' + o.settings.iconMenu + "</span></a></li>"), s(r = d.find("> li.menu-item")) && d.find("> li:nth-last-child(2)").appendTo(S), S.children().each(function(n, e) {
                            S.prepend(e)
                        }), r.append(S), d.find("> li.menu-item > a").click(function(e) {
                            n("li.menu-item.active").not(r).removeClass("active").find("> ul").css("display", "none"), S.toggle(), r.toggleClass("active"), e.preventDefault()
                        })
                    } else if (p() <= o.settings.mobileWidth) {
                        for (f = (c = d.find("ul.transform-submenu")).find("li").length, u = 1; u <= f; u++) c.find("> li:first-child").appendTo(d);
                        c.remove(), d.find("> li.menu-item").remove()
                    }
                } else if (e && d.find("ul.transform-submenu") && p() > o.settings.mobileWidth) {
                    for (f = (c = d.find("ul.transform-submenu")).find("li").length, u = 1; u <= f; u++) c.find("> li:first-child").appendTo(d);
                    c.remove(), d.find("> li.menu-item").remove()
                }
            },
            h = function() {
                var s = n(o.settings.fixedId),
                    i = s.offset().top;
                0 == i && 0 == n(e).scrollTop() ? n(e).scroll(function() {
                    0 == i && 0 == n(e).scrollTop() ? l() : i <= o.settings.topSpace ? t() : n(e).on("scroll", function() {
                        n(e).scrollTop() >= i - o.settings.topSpace ? n(s).hasClass(o.settings.fixedClass) || t() : n(s).hasClass(o.settings.fixedClass) && l()
                    })
                }) : i <= o.settings.topSpace ? t() : n(e).on("scroll", function() {
                    n(e).scrollTop() >= i - o.settings.topSpace ? n(s).hasClass(o.settings.fixedClass) || t() : n(s).hasClass(o.settings.fixedClass) && l()
                });
                var t = function() {
                        n(s).removeClass("no-fixed").addClass(o.settings.fixedClass), a()
                    },
                    l = function() {
                        n(s).removeClass(o.settings.fixedClass).addClass("no-fixed")
                    },
                    a = function() {
                        n(s).css("top", o.settings.topSpace)
                    }
            },
            m = function() {
                n(t).children("ul").find("span.submenu-indicator").bind("click touchstart", function(e) {
                    if (e.stopPropagation(), e.preventDefault(), n(this).parent().siblings("div.catalog-submenu").children(".submenu").length > 0) {
                        if ("none" == n(this).parent().siblings("div.catalog-submenu").children(".submenu").css("display")) return n(this).parent().siblings("div.catalog-submenu").children(".submenu").css("display", "block"), n(this).parent().parent().parent().children(".submenu").css("display", "none"), n(this).parent().parent().parent().children(".submenu").siblings("a").removeClass("submenu-indicator-minus"), n(this).parent().parent().parent().children(".submenu").siblings("a").children("i").css("transform", "rotate(-90deg)"), n(this).children("i").css("transform", "rotate(0deg)"), n(this).addClass("submenu-indicator-minus"), !1;
                        n(this).parent().siblings("div.catalog-submenu").children(".submenu").delay(0).slideUp(0), n(this).hasClass("submenu-indicator-minus") && (n(this).removeClass("submenu-indicator-minus"), n(this).children("i").css("transform", "rotate(-90deg)"))
                    }
                })
            },
            v = function() {
                function e(s, i) {
                    s++;
                    var l = n(i).children("ul").children("li").children(".mobile-level");
                    return l.length > 0 && (n(l).attr("data-level", "level-mobile-" + s), n(l).each(function() {
                        e(s, this)
                    }), function(e, s) {
                        n(e).attr("data-level") === s && n(e).parent().parent().find("> li").find("> a").each(function() {
                            n(this).on("click touchstart", function(e) {
                                var s = this.parentNode.querySelector(".mobile-level");
                                if(s){
                                	e.stopPropagation(), e.preventDefault();
	                                s.style.transitionProperty = "left", s.classList.add("open-mobile"), s.querySelector("a").addEventListener("click", function() {
	                                    n(s).removeClass("open-mobile").on("webkitTransitionEnd moztransitionend transitionend oTransitionEnd", function() {
	                                        n(t).find(".mobile-level").off()
	                                    })
	                                })
                                }else{
                                	//alert($(this).attr('href'));
                                }
                            })
                        })
                    }(l, "level-mobile-" + s)), 0
                }
                var s = n(t).find("> .mobile-level"),
                    i = 0;
                s.length > 0 && s.each(function() {
                    i++, n(this).attr("data-level", "level-mobile-" + i), e(i, this)
                })
            },
            p = function() {
                return e.innerWidth || s.documentElement.clientWidth || s.body.clientWidth
            },
            b = function() {
                return e.innerHeight || s.documentElement.clientHeight || s.body.clientHeight
            };
        o.init()
    }, n.fn.sfNavMulti = function(e) {
        return this.each(function() {
            if (i === n(this).data("sfNavMulti")) {
                var s = new n.sfNavMulti(this, e);
                n(this).data("sfNavMulti", s)
            }
        })
    }
}(jQuery, window, document);