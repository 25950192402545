$(document).ready(function() {
  // WP Mobile Nav (Second method besides Bootstrap standrd menu) - Populating mobile nav list
  var navbarContainer = $('.navbar-nav');
  var linkTextLevel1;
  var linkTextLevel2;
  var linkTextLevel3;
  var navList;
  var navListEnd = '</ul>';

  // Get first level items
  $('.navbar-nav > li.dropdown').each(function(index) {
    // Meganav list items
    if ($(this).hasClass('dropdown-meganav')) {
      linkTextLevel1 = $(this).find('a:first').text();
      linkUrlLevel1 = $(this).find('a:first').attr('href');
      navList = '<li><a href="' + linkUrlLevel1 + '">' + linkTextLevel1 + '</a>';

      // Get second level items
      if ($(this).find('.mega-nav-section').length > 0) {
        navList = navList + '<ul class="dl-submenu">';

        $(this).find('.mega-nav-section').each(function(index) {
          linkTextLevel2 = $(this).find('.mega-nav-section-title').text();
          linkUrlLevel2 = $(this).find('a:first').attr('href');
          navList = navList + '<li><a href="' + linkUrlLevel2 + '">' + linkTextLevel2 + '</a>';

          // Get third level items
          if ($(this).find('.mega-nav-ul').length > 0) {
            navList = navList + '<ul class="dl-submenu">';

            $(this).find('.mega-nav-ul > li').each(function(index) {
              linkTextLevel3 = $(this).find('a').text();
              linkUrlLevel3 = $(this).find('a:first').attr('href');
              navList = navList + '<li><a href="' + linkUrlLevel3 + '">' + linkTextLevel3 + '</a></li>';
            });

            navList = navList + navListEnd;
            navList = navList + '</li>';
          }
        });
      }

    }

    // Normal nav list items
    if (!$(this).hasClass('dropdown-search') && !$(this).hasClass('dropdown-meganav')) {
      linkTextLevel1 = $(this).find('a:first').text();
      linkUrlLevel1 = $(this).find('a:first').attr('href');
      navList = '<li><a href="' + linkUrlLevel1 + '">' + linkTextLevel1 + '</a>';

      // Get second level items
      if ($(this).find('ul > li').length > 0) {
        navList = navList + '<ul class="dl-submenu">';

        $(this).children().children().each(function(index) {
          linkTextLevel2 = $(this).find('a:first').text();
          linkUrlLevel2 = $(this).find('a:first').attr('href');
          navList = navList + '<li><a href="' + linkUrlLevel2 + '">' + linkTextLevel2 + '</a>';

          // Get third level items
          if ($(this).find('ul > li').length > 0) {
            navList = navList + '<ul class="dl-submenu">';

            $(this).children().children().each(function(index) {
              linkTextLevel3 = $(this).find('a').text();
              linkUrlLevel3 = $(this).find('a:first').attr('href');
              navList = navList + '<li><a href="' + linkUrlLevel3 + '">' + linkTextLevel3 + '</a></li>';
            });

            navList = navList + navListEnd;
            navList = navList + '</li>';
          }
        });
      }
    }

    navList = navList + navListEnd;
    navList = navList + '</li>';

    // Append and create list menu
    $('#dl-menu > ul.dl-menu').append(navList + '</ul>');
  });
  // Initializing mobile menu
  $('#dl-menu').dlmenu({
    animationClasses: {
      classin: 'dl-animate-in-2',
      classout: 'dl-animate-out-2'
    }
  });

  allowDropdownHover();
  window.onresize = allowDropdownHover; // Call the function on resize

  //Carousels
  $('.carousel').carousel({
    interval: false,
    pause: 'hover'
  });
  // Owl carousel
  if ($(".owl-carousel-single").length > 0) {
    $(".owl-carousel-single").owlCarousel({
      //items : 4,
      lazyLoad: true,
      pagination: false,
      autoPlay: 10000,
      singleItem: true,
      stopOnHover: true
    });
  }
  if ($(".owl-carousel-2").length > 0) {
    // Owl Carousel
    $(".owl-carousel-2").owlCarousel({
      items: 2,
      lazyLoad: true,
      pagination: false,
      autoPlay: 10000,
      stopOnHover: true
    });
  }
  if ($(".owl-carousel-3").length > 0) {
    // Owl Carousel
    $(".owl-carousel-3").owlCarousel({
      items: 3,
      lazyLoad: true,
      pagination: false,
      autoPlay: 10000,
      stopOnHover: true
    });
  }
  if ($(".owl-carousel-4").length > 0) {
    // Owl Carousel
    $(".owl-carousel-4").owlCarousel({
      items: 4,
      lazyLoad: true,
      pagination: false,
      autoPlay: 10000,
      stopOnHover: true
    });
  }
  if ($(".owl-carousel-5").length > 0) {
    // Owl Carousel
    $(".owl-carousel-5").owlCarousel({
      items: 5,
      lazyLoad: true,
      pagination: false,
      autoPlay: 10000,
      stopOnHover: true
    });
  }
  // Scroll to top
  $().UItoTop();
  // Inview animations
  $.fn.waypoint.defaults = {
    context: window,
    continuous: true,
    enabled: true,
    horizontal: false,
    offset: 300,
    triggerOnce: false
  }
  // Custom animations
  $(".animate-click").click(function() {
    var animateIn = $(this).data("animate-in");
    var animateOut = $(this).data("animate-out");
    var animatedElement = $(this).find(".animate-wr");
    if (animateIn != undefined) {
      if (animatedElement.is(":hidden")) {
        animatedElement.addClass(animateIn);
        animatedElement.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
          animatedElement.removeClass(animateIn);
        });
      }
    }
  });
  $(".animate-hover").hover(function() {
    var animation = $(this).data("animate-in");
    if (animation != undefined || animation != "") {
      $(this).find(".animate-wr").addClass(animation);
    }
  });

  // Main nav for mobiles - left
  $(".navbar-toggle-mobile-nav, #btnHideMobileNav").click(function() {
    if ($("#navMobile").is(":visible")) {
      $("#navMobile").removeClass('animated bounceInLeft');
      $("#navMobile").addClass('animated bounceOutLeft');
      $('#navMobile').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
        $(this).hide();
        $("#navMobile").removeClass('animated bounceOutLeft');
      });
      $("body").removeClass("nav-menu-in");
    } else {
      $("#navMobile").show().addClass('animated bounceInLeft');
      $("body").addClass("nav-menu-in");
    }
    return false;
  });

  // Calendar
  $("#filter_date_in, #filter_date_out").datepicker({
    dateFormat: 'MM dd, yy',
    minDate: 0,
    showOtherMonths: true
  });

  // Optional filters
  $("#btnToggleOptionalFIlters").click(function() {
    var animateIn = $(this).data("animate-in");
    var animateOut = $(this).data("animate-out");

    if ($(this).hasClass("opened")) {
      $(".hidden-form-filters").addClass('hide');
      $(this).removeClass("opened");
    } else {
      $(this).addClass("opened");
      $(".hidden-form-filters").removeClass("hide");
    }
    return false;
  });

  // Layer Slider Dynamic- Set height to fit navbar
  if ($(".layer-slider-dynamic").length > 0) {
    layerSliderDynamic();
  }

  // Layer Slider Fullsize
  if ($(".layer-slider-fullsize").length > 0) {
    layerSliderFullsize();
  }

  // Window resize events
  $(window).resize(function() {
    if ($(".layer-slider-dynamic").length > 0) {
      layerSliderDynamic();
    }
    if ($(".layer-slider-fullsize").length > 0) {
      layerSliderFullsize();
    }
  });

  var screenRes = $(window).width(),
    screenHeight = $(window).height(),
    html = $('html');

  // IE<8 Warning
  if (html.hasClass("ie8")) {
    $("body").empty().html('Please, Update your Browser to at least IE9');
  }

  // Disable Empty Links
  $("[href='#']").click(function(event) {
    event.preventDefault();
  });

  // Tooltip & Popover
  $('.ttip').tooltip({
    placement: $(this).data('toggle'),
    html: true
  });

  $('.pop').popover({
    placement: 'right',
    html: true
  });
});

// Bootstrap - Dropdown Hover
function allowDropdownHover() {
  if ($(window).width() > 767) {
    $('.dropdown-toggle').dropdownHover();
  }
}

// Functions
function layerSliderDynamic() {
  var windowHeight = $(window).height();
  var headerHight = $("#divHeaderWrapper").height();
  var newSliderHeight = windowHeight - headerHight;
  $("#layerslider").css({ "height": newSliderHeight + "px" });
}

function layerSliderFullsize() {
  var windowHeight = $(window).height();
  $("#layerslider").css({ "height": windowHeight + "px" });
}